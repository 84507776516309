.Nav {
    width: 100%;
}
.Nav .nav_links_row{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    height: 80px;
}
.Nav .Link_Button.label {
    font-size: 1.5em;
}
.Nav h1 {
    display: none;
    margin: 0;
    padding: 0;
    padding-left: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 3.0em;
    background-color: #217170;
    color: #DCe1e9;
}


@media screen and (max-width: 1000px) {
    .Nav h1 {
        display: none;
    }
}
@media screen and (max-width: 400px) {
    .Nav .nav_links_row{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        width: 100%;
        height: 100%;
    }
}