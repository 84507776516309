@import url(https://fonts.googleapis.com/css?family=Raleway);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Link_Button.label{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    width: 100%;
    text-decoration: none;
    font-weight: 600;
}
.wrapper {
    text-decoration: none;
    display: grid;
    grid-row: 1fr;
    width: 100%;
    height: 100%;
}
.button-home {
    background-color: #DCe1e9;
    color: #363732;
}
.button-blog {
    background-color: #dd614a;
    color: #DCe1e9;
}
.button-external {
    background-color: #cca43b;
    color: #363732
}
.button-code {
    background-color: #363732;
    color: #DCe1e9;
}
.button-calendar {
    background-color: #129490;
    color: #DCe1e9;

}
.button-recipe {
    background-color: white;
    color:#dd614a;
}
.button-resume {
    background-color: #DCe1e9;
    color:#129490;
}
.Nav {
    width: 100%;
}
.Nav .nav_links_row{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    height: 80px;
}
.Nav .Link_Button.label {
    font-size: 1.5em;
}
.Nav h1 {
    display: none;
    margin: 0;
    padding: 0;
    padding-left: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 3.0em;
    background-color: #217170;
    color: #DCe1e9;
}


@media screen and (max-width: 1000px) {
    .Nav h1 {
        display: none;
    }
}
@media screen and (max-width: 400px) {
    .Nav .nav_links_row{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        width: 100%;
        height: 100%;
    }
}
h2 {
    font-family: 'Raleway', sans-serif;
    font-size: 1.75em;
    font-weight: 900;
}
.intro-pic {
    width: 100%;
    height: 100%;
    max-width: 335px;
    max-height: 445px;
}
.intro-socials {
    display: flex;
    justify-content: left;
}
.intro-socials a {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #365039;
    font-size: small;
    background-color: rgb(221, 177, 177);
    padding: 3px;
    margin-left: 20px;
}

.outlined {
    color:black;
    font-size: 40px;
    letter-spacing: 1px;
    -webkit-text-stroke: .1px black;
    -webkit-text-fill-color: transparent;

}
.Home {
    padding: 10px;
    background: linear-gradient(45deg,white,#365039); 
    height: 100%;
}
.intro {
    padding: 10px;
    background-color: white;
}
.bio {
    padding: 10px;
    margin-top: 10px;
    background-color: white;
}
.bio p {
    font-size: 1.2em;
}
.bio a {
    background-color: rgb(221, 177, 177);
    padding: 5px;
    font-size: 1.4em;
}
.bio div {
    padding-top: 10px;
    padding-bottom: 10px;
}
.footer {
    position: relative;
    bottom: 0;
    right: 0;
    font-family: 'Raleway', sans-serif;
    opacity: .6;
}
a {
    -webkit-text-decoration-style: dotted;
            text-decoration-style: dotted;
    color: inherit;
}

@media screen and (min-width: 800px) {
    .Home {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .bio {
        margin-top: 0px;
        margin-left: 10px;
    }
    
}
.Projects {
    padding: 10px;
    background: linear-gradient(135deg,rgb(199, 199, 199),#c6a0d1 66%, white); 
    height: 100%;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
}
.ProjectCard {
    width: 98%;
    height: 95%;
    background-color: white;
    color: rgba(0,0,0,.54);
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 10px;
}
.projects-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.Projects h2 {
    font-size: 2em;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
}
.ProjectCard h2 {
    font-size: 1.5em;
    color: rgba(0,0,0,.87);
}
.ProjectCard img {
    width: 100%;
    height: 100%;
    max-height: 600px;
    
}
.ProjectCard p span {
    font-feature-settings: "smcp", "c2sc";
    font-variant: all-small-caps;
}
.projectcard-externals{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    text-align: center;
}
.projectcard-externals a {
    display: flex;
    justify-content: center;
    align-items: center;
}
.projectcard-externals img {
    width: 30px;
    height: 30px;
}

@media screen and (min-width: 800px) {
    .Projects {
        padding-left: 5%;
        padding-right: 5%;
    }
    .ProjectCard img {
        max-width: 400px;
        max-height: 400px;
    }
    .ProjectCard {
        display: flex;
        flex-direction: column;
        padding-bottom: 5%;
    }
    .projects-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 1300px) {
    .Projects {
        padding-left: 7.5%;
        padding-right: 7.5%;
    }
    .projects-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}
.toolbar {
    border:solid 1px #ddd;
    background:#f4f4f4;
    padding: 5px;
    border-radius:3px;
    position: fixed;
    top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .hidden {
    display: none;
  }
.body {
    width: 95%;
    max-width: 700px;
    min-height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-size: 20px;
  }
  .body > li {
    list-style: square;
    margin: 0;
    margin: initial
  }
  #title {
    width: 95%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 135px;
    font-size: 40px;
  }
  .body:focus, #title:focus {
    outline: none;
  }
  #title:empty:before, .body:empty:before {
    content:  attr(data-placeholder);
    color: gray;
  }
  .codeBlock {
    background-color: rgb(21, 34, 75);
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
    min-height: 39px;
    font-family: 'Courier New', Courier, monospace;
    overflow: auto;
    color: white;
    padding: 8px;
  }
._Blog *{
    display: flex;
    flex-direction: column;
    color: black;
    height: 100%;
    width: 100%;
    text-align: center;
}
.posts {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    height:100%;
    width: 100%;
    background-color: #129490;
}
.posts a {
    text-decoration: none;
}
.blog-post-wrapper {
    min-width: 360px;
    min-height: 500px;
}
.posts.personal{
    background-color: #DCe1e9;
    background-color: #dd614a;
}
.posts.personal .blog-post {
    color: #DCe1e9;
    background-color: #dd614a;
    background-color: #DCe1e9;
    color: #363732;
}
.posts.code .blog-post{
    color: #DCe1e9;
    background-color: #363732;
    background-color: #DCe1e9;
    color:#363732;
}
.posts.code{
    background-color: #DCe1e9;
    background-color: #363732;
}
.posts.code h1{
    color: #DCe1e9;
}
.posts.food {
    background-color: #dd614a;
    background-color: white;
}
.posts.food .blog-post{
    background-color: white;
    color:#dd614a;
    border: 4px solid #dd614a;
}
.blog-post {
    padding: 1% 2%;
    color: #363732;
    background-color:#DCe1e9;
    border:4px solid black;

}
.blog-post h3{
    text-align: left;
}
.PostDescription {
}
.topics {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
}
.topic {
    padding-left: 5%;
    text-align: center;
}
.AdminDash {
    height: 100%;
}
* {
    box-sizing: border-box;
}
html {
    height: 100%;
    width: 100%;
    min-width: 360px;
}
.App {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    height: 100%;
    min-height: 100vh;
    width: 100%;
}
