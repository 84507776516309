.body {
    width: 95%;
    max-width: 700px;
    min-height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-size: 20px;
  }
  .body > li {
    list-style: square;
    margin: initial
  }
  #title {
    width: 95%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 135px;
    font-size: 40px;
  }
  .body:focus, #title:focus {
    outline: none;
  }
  #title:empty:before, .body:empty:before {
    content:  attr(data-placeholder);
    color: gray;
  }
  .codeBlock {
    background-color: rgb(21, 34, 75);
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
    min-height: 39px;
    font-family: 'Courier New', Courier, monospace;
    overflow: auto;
    color: white;
    padding: 8px;
  }