* {
    box-sizing: border-box;
}
html {
    height: 100%;
    width: 100%;
    min-width: 360px;
}
.App {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    height: 100%;
    min-height: 100vh;
    width: 100%;
}