h2 {
    font-family: 'Raleway', sans-serif;
    font-size: 1.75em;
    font-weight: 900;
}
.intro-pic {
    width: 100%;
    height: 100%;
    max-width: 335px;
    max-height: 445px;
}
.intro-socials {
    display: flex;
    justify-content: left;
}
.intro-socials a {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #365039;
    font-size: small;
    background-color: rgb(221, 177, 177);
    padding: 3px;
    margin-left: 20px;
}

.outlined {
    color:black;
    font-size: 40px;
    letter-spacing: 1px;
    -webkit-text-stroke: .1px black;
    -webkit-text-fill-color: transparent;

}
.Home {
    padding: 10px;
    background: linear-gradient(45deg,white,#365039); 
    height: 100%;
}
.intro {
    padding: 10px;
    background-color: white;
}
.bio {
    padding: 10px;
    margin-top: 10px;
    background-color: white;
}
.bio p {
    font-size: 1.2em;
}
.bio a {
    background-color: rgb(221, 177, 177);
    padding: 5px;
    font-size: 1.4em;
}
.bio div {
    padding-top: 10px;
    padding-bottom: 10px;
}
.footer {
    position: relative;
    bottom: 0;
    right: 0;
    font-family: 'Raleway', sans-serif;
    opacity: .6;
}
a {
    text-decoration-style: dotted;
    color: inherit;
}

@media screen and (min-width: 800px) {
    .Home {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .bio {
        margin-top: 0px;
        margin-left: 10px;
    }
    
}