.Projects {
    padding: 10px;
    background: linear-gradient(135deg,rgb(199, 199, 199),#c6a0d1 66%, white); 
    height: 100%;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
}
.ProjectCard {
    width: 98%;
    height: 95%;
    background-color: white;
    color: rgba(0,0,0,.54);
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 10px;
}
.projects-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.Projects h2 {
    font-size: 2em;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
}
.ProjectCard h2 {
    font-size: 1.5em;
    color: rgba(0,0,0,.87);
}
.ProjectCard img {
    width: 100%;
    height: 100%;
    max-height: 600px;
    
}
.ProjectCard p span {
    font-variant: all-small-caps;
}
.projectcard-externals{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    text-align: center;
}
.projectcard-externals a {
    display: flex;
    justify-content: center;
    align-items: center;
}
.projectcard-externals img {
    width: 30px;
    height: 30px;
}

@media screen and (min-width: 800px) {
    .Projects {
        padding-left: 5%;
        padding-right: 5%;
    }
    .ProjectCard img {
        max-width: 400px;
        max-height: 400px;
    }
    .ProjectCard {
        display: flex;
        flex-direction: column;
        padding-bottom: 5%;
    }
    .projects-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 1300px) {
    .Projects {
        padding-left: 7.5%;
        padding-right: 7.5%;
    }
    .projects-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}