.Link_Button.label{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    width: 100%;
    text-decoration: none;
    font-weight: 600;
}
.wrapper {
    text-decoration: none;
    display: grid;
    grid-row: 1fr;
    width: 100%;
    height: 100%;
}
.button-home {
    background-color: #DCe1e9;
    color: #363732;
}
.button-blog {
    background-color: #dd614a;
    color: #DCe1e9;
}
.button-external {
    background-color: #cca43b;
    color: #363732
}
.button-code {
    background-color: #363732;
    color: #DCe1e9;
}
.button-calendar {
    background-color: #129490;
    color: #DCe1e9;

}
.button-recipe {
    background-color: white;
    color:#dd614a;
}
.button-resume {
    background-color: #DCe1e9;
    color:#129490;
}