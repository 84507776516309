.posts {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    height:100%;
    width: 100%;
    background-color: #129490;
}
.posts a {
    text-decoration: none;
}
.blog-post-wrapper {
    min-width: 360px;
    min-height: 500px;
}
.posts.personal{
    background-color: #DCe1e9;
    background-color: #dd614a;
}
.posts.personal .blog-post {
    color: #DCe1e9;
    background-color: #dd614a;
    background-color: #DCe1e9;
    color: #363732;
}
.posts.code .blog-post{
    color: #DCe1e9;
    background-color: #363732;
    background-color: #DCe1e9;
    color:#363732;
}
.posts.code{
    background-color: #DCe1e9;
    background-color: #363732;
}
.posts.code h1{
    color: #DCe1e9;
}
.posts.food {
    background-color: #dd614a;
    background-color: white;
}
.posts.food .blog-post{
    background-color: white;
    color:#dd614a;
    border: 4px solid #dd614a;
}
.blog-post {
    padding: 1% 2%;
    color: #363732;
    background-color:#DCe1e9;
    border:4px solid black;

}
.blog-post h3{
    text-align: left;
}
.PostDescription {
}
.topics {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
}
.topic {
    padding-left: 5%;
    text-align: center;
}